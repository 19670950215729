<template>
  <base-modal-ex :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="60"
    has-modal-card>
    <header class="modal-card-head">
      <p class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span>Add or Edit Category</span>
      </p>
    </header>
    <section class="modal-card-body">

      <div class="columns">
        <div class="column">
          <label>Create New Category</label>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <input type="text"
            class="input"
            v-model="newCategoryName"
            placeholder="Please enter a new Name">
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <div class="button is-secondary"
            @click="newCategoryName = ''">Cancel</div>
        </div>
        <div class="
            column">
          <div class="button is-primary is-pulled-right"
            @click="createNewCategory()">Create</div>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <label>Edit Existing Category</label>
        </div>
      </div>

      <div class="columns"
        v-for="category in categories"
        :key="category.quoteAttachmentCategoryId">
        <div class="column">
          <div class="field has-addons">
            <input class="input"
              type="text"
              v-model="category.name"
              placeholder="Existing Name"
              @keyup="setDirty(category)">
            <div class="control">
              <a class="button is-secondary has-text-danger"
                @click="deleteCategory(category)">
                <i class="mdi mdi-trash-can mdi-18px" />
              </a>
            </div>
          </div>
        </div>
      </div>

    </section>

    <footer class="modal-card-foot">

      <div :style="`width: 100%; display: flex; flex-direction: row-reverse;`">

        <div style="align-self: flex-start;">
          <button class="button tooltip"
            @click="cancel()"
            data-tooltip="Cancel">
            <span>Cancel</span>
          </button>
          <button class="button is-primary tooltip"
            @click="saveCategories()"
            data-tooltip="Save">
            <span>Save</span>
          </button>
        </div>
      </div>
    </footer>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import QuoteService from '@/views/quote/QuoteService'

export default {
  name: 'QuoteAttachmentCategoryModal',
  components: {
    BaseModalEx
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'warning'
    },
    iconName: {
      type: String,
      default: 'mdi-alert-decagram'
    }
  },
  data() {
    return {
      isActive: this.active || false,
      newCategoryName: '',
      categories: []
    }
  },
  computed: {},
  watch: {
    active(value) {
      this.loadQuoteAttachmentCategories()
      this.isActive = value
    }
  },
  created() {},
  mounted() {
    this.loadQuoteAttachmentCategories()
  },
  methods: {
    async createNewCategory() {
      await QuoteService.createQuoteAttachmentCategory(this.newCategoryName)
      this.$emit('categories-changed')
      this.loadQuoteAttachmentCategories()
      this.newCategoryName = ''
    },

    async deleteCategory(row) {
      await QuoteService.updateQuoteAttachmentCategory(row.quoteAttachmentCategoryId, row.name, true)
      this.$emit('categories-changed')
      this.loadQuoteAttachmentCategories()
    },
    async saveCategories() {
      for (var row of this.categories) {
        if (!row.isDirty) {
          continue
        }

        await QuoteService.updateQuoteAttachmentCategory(row.quoteAttachmentCategoryId, row.name, false)
      }

      this.$emit('categories-changed')
      this.loadQuoteAttachmentCategories()
      this.$emit('cancel')
    },
    setDirty(category) {
      category.isDirty = true
    },
    async loadQuoteAttachmentCategories() {
      this.categories = await QuoteService.getQuoteAttachmentCategories()
    },
    cancel() {
      this.$emit('cancel')
    }
  }
}
</script>
